<template lang="pug">
v-container(fluid).azul
  v-row.justify-center.align-center
    v-container(style='margin-top: 1em')
      v-row.justify-center.align-center
        v-col(cols=12 md=5).justify-center.align-center
          h2.white--text(v-html='arbitros_encabezado.título').text-center

  v-row.justify-center.align-center(style="padding-bottom:2rem; padding-left:2rem; padding-right:2rem").azul
    v-col(cols=12 md=6)
      v-list(dense).azul
        v-list-item-group(v-model='selectedItem')
          template(v-for='{nombre, id} in paginatedArbitros')
            v-list-item(key='id' style="border: 1px solid #d6a772; border-radius: 8px; margin-bottom: 1em;box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);")
              v-list-item-content
                v-list-item-title(style="font-weight: bold; color: white;") {{ nombre }}
         

  v-row.justify-center.align-center
    v-pagination(:length='totalPages' v-model='currentPage')
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 6,
      selectedItem: null
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.arbitros_encabezado.listado_de_arbitros.length / this.itemsPerPage);
    },
    paginatedArbitros() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.arbitros_encabezado.listado_de_arbitros.slice(start, end);
    }
  }
};
</script>
